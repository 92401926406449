body {
	background-color: #f2f2f2;
}

a {
	color: $rt-color-main;
	text-decoration: none;
}

a:hover {
	color: $rt-color-main;
	text-decoration: underline;
}

.navbar {
	background-color: $rt-color-main;
	border-bottom: 0.25em solid $rt-color-dark;
	background-image: $rt-corner;
    background-repeat: no-repeat;
	color: $white;

	.rt-site-logo {
		width: 60px;
		position: absolute;
		top: 10px;
		z-index: 5;
	}

	@media screen and (max-width: 991px) {
		.rt-site-logo {
			width: 50px;
			position: inherit;
		}
	}

	a {
		color: $white !important;
	}

	a:hover, a:focus {
		color: $gray-400;
		text-decoration: none;
	}

	.dropdown-menu {
		border-radius: 0;
		background-color: black;

		a:hover {
			background-color: #4f4f4f;
		}
	}
}

.dropdown-menu li {
	position: relative;
}

.dropdown-menu .dropdown-submenu {
	display: none;
	position: absolute;
	left: 100%;
	top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
	right: 100%;
	left: auto;
}

.dropdown-menu > li:hover > .dropdown-submenu {
	display: block;
}

@media screen and (max-width: 991px) {
	.dropdown-menu .dropdown-submenu {
		display: block;
		position: initial;
		padding-left: 15px;
	}
}

.header-banner.warning {
	background-color: #FFCC00;
	padding: 5px;
	text-align: center;

	p {
		margin: 0;
	}

	a {
		color: #000;
		text-decoration: underline;
	}
}

.rt-homegrid {
	width: 100%;
	height: 250px;
	background-size: cover;
	background-position: center;
}

.rt-alert {
	padding: 1em;
	background-color: #E7E7E7;
	border-bottom: solid 4px #8E8E8E;
	color: #333;

	a {
		color: #000;
		text-decoration: underline;
	}

	&.info {
		background-color: #66CCFF;
		border-bottom-color: #3E94C0;
	}

	&.rt-alert.success {
		background-color: #339900;
		border-bottom-color: #205F00;
		color: #FFF;
	}
	
	&.rt-alert.warning {
		background-color: #FFCC00;
		border-bottom-color: #C69E00;
	}
	
	&.rt-alert.error,
	&.rt-alert.danger {
		background-color: #CC3300;
		border-bottom-color: #761D00;
		color: #dcdcdc;
	}
	
	&.rt-alert.error a,
	&.rt-alert.danger a {
		color: #fff;
	}
}

.rt-button {
	border: none;
	background-color: #138e96 !important;
	border-bottom: solid .2em #0b4e53 !important;
	text-shadow: 0 1px 1px #000;
	border-radius: 0;
	margin: 20px 0;
	display: inline-block;
	padding: 10px 20px;
	color: #FFF;

	&:hover {
		color: #FFF;
	}

	a {
		color: #FFF;
		text-decoration: none;
	}
}

.rt-banner {
	background-size: cover;
	background-position-y: -100px;
	color: $white;
	padding-top: 12rem !important;
    padding-bottom: 2rem !important;

	h1 {
		display: inline-block;
		background-color: $rt-color-main;
		border-bottom: 0.1em solid $rt-color-dark;
		background-image: $rt-corner;
		background-repeat: no-repeat;
		padding: 5px 20px;
		margin-bottom: 0;
	}

	p {
		background-color: rgba(59,59,59,.8);
    	padding: 5px;
	}
}

.article-image {
	text-align: center;

	img {
		max-width: 600px;

		@media screen and (max-width: 600px) {
			width: 100%;
		}
	}
}

.article-image-sm {
	text-align: center;

	img {
		max-width: 100px;
	}
}

.rt-columncase {
	img {
		max-width: 125px;
	}
}

.picto {
	background-size: cover;
	background-position: center;
}

.rt-psvita-apps {
	img {
		border-radius: 9999px;
	}
}

.rt-bordering {
	background-color: #ffffff;
	border: 1px solid #dadada;
}

.rt-beta{
	background-color: #FFCC00;
	color: #000;

	p {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}
}

.footer {
	background-color: #1f1f1f;
    border-top: 0.25em solid #3a3a3a;
	color: #fff;

	a {
		color: #fff;
	}
}

.dark-mode {
	background-color: #282828;
	color: #fff;

	.rt-bordering {
		background-color: #333;
		border-color: #212121;
	}
}

.e404 {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 150;
	background-color: #a5a5ff;
	padding: 20px;
	font-family: monospace;
	color: #a5a5ff;

	p, h1, li {
		text-transform: uppercase;
		margin-bottom: 2px;
	}

	ul {
		list-style-type: square;
	}

	a {
		text-decoration: underline;
		color: inherit;
	}

	div {
		width: 100%;
		height: 100%;
		background-color: #4343e7;
		padding: 5px;
	}

	.cmctr {
		text-align: center;
	}

	.flashtilde {
		animation: blinker 1.5s step-start infinite;
	}
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
